var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-title',[_vm._t("prepend"),(!_vm.hideSearchInput)?_c('b-col',[_c('b-form-group',{attrs:{"id":"search-group","label-for":"search-input"}},[_c('FormInput',{attrs:{"type":"text","i18n":{
          label: 'LABELS.SEARCH',
          placeholder: _vm.placeholder || _vm.$t('LABELS.SEARCH'),
        },"validateState":{}},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('do-search')}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1):_vm._e(),(!_vm.hideActions)?_c('div',[_c('v-tooltip',{attrs:{"color":"primary","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('do-search')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("search")])],1)]}}],null,false,1481889100)},[_c('span',[_vm._v(_vm._s(_vm.$t("LABELS.SEARCH")))])]),_c('v-tooltip',{attrs:{"color":"red","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.$emit('do-clear')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)]}}],null,false,2803773252)},[_c('span',[_vm._v(_vm._s(_vm.$t("LABELS.CLEAR")))])])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }